<template>
  <div class="ml-16 level d-f">
    <table class="level-tbl f-md" style="min-width: calc(114px + 100px * 5)">
      <thead>
        <tr>
          <th class="f-nm"><t path="levelandpoints_5">Levels</t></th>
          <th v-for="item in levelInfo.header" :key="item" :class="{curLevel: level === item}">
            <Icon name="membership" class="c-grey mr-4" />
            <span class="c-title">{{ item }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in levelInfo.body" :key="index">
          <td><t :path="item.title">title</t></td>
          <td v-for="(sub, i) in item.value" :key="i" :class="{curLevel: level === i}">
            <t v-if="sub.i18nValue" :path="sub.i18nValue">——</t>
            <span v-else>{{ sub }}</span>
<!--            <div class="ok c-white"><Icon name="check" sm /></div>-->
          </td>
        </tr>
      </tbody>
    </table>
    <div class="p-8"></div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import state from '@/state'

export default defineComponent({
  name: 'TheLevelContent',
  components: { Icon },
  setup () {
    return {
      level: computed(() => state.account.levelModel.level),
      levelInfo: {
        header: [0, 1, 2, 3, 4],
        body: [
          {
            title: 'levelandpoints_6',
            value: ['$0', '$20', '$200', '$1000', '$5000'],
          }, {
            title: 'levelandpoints_7',
            value: ['——', '——', '5%', '10%', '20%'],
          }, {
            title: 'levelandpoints_8',
            value: ['——', '——', '——', '——', { i18nValue: 'levelandpoints_14' }],
          }, {
            title: 'levelandpoints_9',
            value: [
              { i18nValue: 'levelandpoints_10' },
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
            ],
          }, {
            title: 'levelandpoints_13',
            value: [
              '——',
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
            ],
          },
          {
            title: 'levelandpoints_17',
            value: [
              '——',
              { i18nValue: 'levelandpoints_11' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
              { i18nValue: 'levelandpoints_12' },
            ],
          },
          {
            title: 'levelandpoints_18',
            value: [
              '——',
              '——',
              '——',
              { i18nValue: 'levelandpoints_14' },
              { i18nValue: 'levelandpoints_14' },
            ],
          },
          {
            title: 'levelandpoints_19',
            value: ['——', '——', '$10', '$50', '$200'],
          },
          {
            title: 'levelandpoints_20',
            value: ['——', '3', '10', '30', '50'],
          },
          {
            title: 'levelandpoints_24',
            value: [
              '——',
              { i18nValue: 'levelandpoints_25' },
              { i18nValue: 'levelandpoints_25' },
              { i18nValue: 'levelandpoints_25' },
              { i18nValue: 'levelandpoints_25' },
            ],
          },
        ],
      },

    }
  },
})
</script>
<style scoped lang="scss">
  .level {
    overflow: auto;
  }

  .level-tbl {
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #D5DEE8;

    tr:nth-child(2n+1) td {
      background: var(--color-background);
      &.curLevel {
        background: var(--color-asst-light);
      }
    }

    th, td {
      width: 100px;
      border-left: 1px solid #D5DEE8;
      &:first-child {
        width: 114px;
      }
    }

    th {
      padding: 20px 5px;
      &.curLevel {
        background: #FFF8F0;
        border: 1px solid rgba(241, 146, 49, 0.5);
        border-bottom: none;
        .icon, span {
          color: var(--color-asst);
        }
      }
    }

    td {
      span {
        line-height: 18px;
        display: inline-block;
      }
      padding: 8px 5px;
      &:first-child {
        color: #667C8D;
      }
      &.curLevel {
        color: var(--color-asst);
        background: #FFF8F0;
        border-left: 1px solid rgba(241, 146, 49, 0.5);
        border-right: 1px solid rgba(241, 146, 49, 0.5);
      }
    }

    tbody tr:last-child {
      .curLevel {
        border-bottom: 1px solid rgba(241, 146, 49, 0.5);
      }
    }

  }

  .ok {
    width: 16px;
    height: 16px;
    background: var(--color-success);
    border-radius: 50%;
    margin: auto;
  }
</style>

<template>
  <PageWithHeader>
    <template #title>
      <t path="levelandpoints_2">Level and Points</t>
    </template>
    <div>
      <router-link to="/membership/about" class="px-16 c-asst f-sm df-middle about">
        <Icon name="help" sm class="mr-8" />
        <t path="membership_4" class="flex-1">How WeTrade Membership works?</t>
        <Icon name="caret-right" sm />
      </router-link>
      <div>
        <div class="p-16 df-middle df-between">
          <p class="f-bold f-xl"><t path="levelandpoints_3">Level</t>
            {{ levelModel.level }}</p>
          <p class="c-title">
            <t path="levelandpoints_4">Net Deposit</t>
            <money class="ml-4" :v="levelModel.totalExp" />
          </p>
        </div>
        <TheLevelContent />
        <t as="p" path="levelandpoints_15" class="mt-16 mb-32 c-title f-xs px-16">
          *Under development, will be added in subsequent versions
        </t>
        <router-link to="/in" class="mt-24 btn primary df-cross-center f-lg f-bold mx-16" style="border-radius: 2px">
          <t path="trade_12">Deposit</t>
        </router-link>
      </div>
      <div class="py-32 px-16">
        <p class="f-bold mb-16 f-xl">
          <t path="levelandpoints_21">Points</t>
          {{ levelModel.validPoints }}
          <router-link to="/membership/level/points" class="pull-right f-sm c-title df-middle" style="font-weight: normal;">
            <t path="levelandpoints_22">See more</t><Icon name="caret-right" />
          </router-link>
        </p>
        <Holder :content="pointsList">
          <List :list-data="pointsList" #="{ item }">
            <ThePointsItem :list="item" />
          </List>
        </Holder>
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { computed, defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import TheLevelContent from '@/modules/membership/components/TheLevelContent.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import ThePointsItem from '@/modules/membership/components/ThePointsItem.vue'
import { Points, readPoints } from '@/modules/membership/member.api'
import state from '@/state'

export default defineComponent({
  name: 'LevelPoints',
  components: { ThePointsItem, Holder, List, TheLevelContent, Icon, PageWithHeader },
  setup () {
    const pointsList = ref<Array<Points> | null>(null)

    readPoints({ page: 1, pageCount: 10 })
      .then(resp => pointsList.value = resp as Array<Points>)

    return {
      pointsList,
      levelModel: computed(() => state.account.levelModel),
    }
  },
})
</script>

<style scoped>
  .about {
    background: var(--color-asst-light);
    height: 32px;
    padding-right: 8px;
  }
</style>
